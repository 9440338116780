import React from "react";

import styled from "styled-components";
import "./style.css";
import logoAscension from "../../assets/ascension_training_platform_logo_black.svg";
import appstore from "../../assets/app-store.png";
import googleplay from "../../assets/google-play.png";
import invite_code_new_user from "../../assets/invite_code_new_user.png";
import invite_code_existing_user from "../../assets/invite_code_existing_user.png";
import { Logo } from "../styledcomponents";

const Seperator = styled.div`
  border-top: 1px solid rgb(227, 232, 233);
  height: 1px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const H2 = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: black;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: ${props => (props.lineheight ? props.lineheght : "20px")};
  font-size: ${props => (props.size ? props.size : "16px")};
  margin-top: 0px;
  margin-bottom: 0px;
`;

const Text = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: ${props => (props.center ? "center" : "left")};
  color: black;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: ${props => (props.lineheight ? props.lineheght : "20px")};
  font-size: ${props => (props.size ? props.size : "16px")};
  margin-top: ${props => (props.margintop ? props.margintop : "0px")};
  margin-bottom: ${props => (props.marginbottom ? props.marginbottom : "0px")};
`;

const SmallText = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  color: #999;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: ${props => (props.lineheight ? props.lineheght : "20px")};
  font-size: ${props => (props.size ? props.size : "16px")};
  margin-top: 20px;
  margin-bottom: 0px;
  // margin-block-start: 0px;
  // margin-block-start: 0px;
`;

const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem;
`;
const StoreLogo = styled.img`
  display: block;
  height: 38px;
`;
const AccessCode = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: black;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 32px;
  font-size: 30px;
  font-weight: 700;
  margin_top: 0px;
  margin-bottom: 0px;
  letter-spacing: 3px;
  margin-block-start: 0px;
`;
const AccessCodeLabel = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: black;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 18px;
  font-size: 16px;
  margin_top: 0px;
  margin-bottom: 0px;
`;

export const TrialTitle = styled.div`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: rgb(26, 46, 59);
  font-weight: 700;
  font-size: 22px;
  text-rendering: optimizelegibility;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const InviteCodeImage = styled.img`
  width: 100%;
`;

class PurchaseSuccess extends React.Component {
  componentDidMount = () => {
    document.title = "Ascension Toolbox | Buy";
    window.scrollTo(0, 0);
  };
  render() {
    let { state } = this.props.location;
    let email, accesscode;
    if (state) ({ email, accesscode } = this.props.location.state);
    return (
      <div className="purchase-success-container">
        <div className="purchase-success-inner-container">
          <div>
            <Logo src={logoAscension} />
          </div>
          <TrialTitle>Thanks for purchasing!</TrialTitle>
          <H2>
            We've sent your receipt and access information to
            {email ? "" : " the email you provided."}
          </H2>
          {email ? (
            <H2>
              <strong>{email}</strong>
            </H2>
          ) : null}

          <SmallText size={"12px"} lineheight={"14px"}>
            If you accidentally entered an incorrect email or haven't received a
            follow-up email, please send us an email at team@ascensionapp.com.
          </SmallText>
          <Seperator />
          <Text>
            <strong>Next Steps:</strong>
          </Text>
          <Text margintop={"10px"}>
            1. Load up the Ascension Training Platform mobile app.
          </Text>
          <DownloadWrapper>
            <a
              className="appstorelogo"
              href="https://itunes.apple.com/us/app/ascensionfitness/id1299489015?ls=1&mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreLogo src={appstore} />
            </a>
            <a
              className="appstorelogo"
              href="https://play.google.com/store/apps/details?id=com.ascensionfitness"
              target="blank"
              rel="noopener noreferrer"
            >
              <StoreLogo src={googleplay} />
            </a>
          </DownloadWrapper>

          <Text margintop={"20px"}>2. And...get to work!</Text>
          <Text margintop={"30px"} center>
            More info on how to use the app can be found{" "}
            <a
              href="https://www.ascensionapp.com/how-to-use-the-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              HERE.
            </a>
          </Text>
          <Text margintop={"10px"} center>
            Questions/Support: team@ascensionapp.com
          </Text>
        </div>
      </div>
    );
  }
}
export default PurchaseSuccess;
