import styled from "styled-components";
import logo_src from "../../assets/ascension_training_platform_logo.svg";
import "./style.css";

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => (props.primary ? "palevioletred" : "white")};
  color: ${props => (props.primary ? "white" : "palevioletred")};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

const Logo = styled.img`
  height: 25px;
  display: block;
`;

function header(props) {
  return (
    <div className="header">
      {props.header ? <Logo src={logo_src} /> : null}
    </div>
  );
}

export default header;
