import logo513 from "../../assets/climb513_logo.png";
import logoAscension from "../../assets/ascension_training_platform_logo.svg";

const ascensionToolboxBase = {
  select_addon: false,
  show_upsell: false,
  logo: logoAscension,
  logo_height: "25px",
  product_name: "The Toolbox",
  description1:
    "You get the whole toolbox. Everything. Ian's entire brain, in app form.",
  buy_button_text: "GET THE TOOLBOX",
  terms:
    "Prices are in USD. The Training Toolbox is an on-going subscription, which can be cancelled at any time. Please make sure your email is correct. Terms and Conditions Apply.",
  document_title: "Ascension Toolbox | Buy",
  monthly_key: "ascension_toolbox_monthly_01_web_usd",
  annual_key: "ascension_toolbox_annual_01_web_usd",
  annual_multiplier: 12,
  paybutton_color: "#33cc99",
  purchase_success_url: "/purchase-success",
  select_monthly: true,
  show_save_badge: true,
  show_yearly_cost: false
};

const base513 = {
  select_addon: false,
  show_upsell: true,
  logo: logo513,
  logo_height: "40px",
  product_name: "Beta Program",
  description1:
    "It's not just about finger strength, or spending more time on the campus board. Climbing harder, sending faster, and staying injury free requires a full-body approach to training. Based on data captured through the Simple-X test, The 5.13 Beta Program is designed to get your body in shape for a more diversified training load, and then improve your climbing-specific strength. With what's going on with lockdowns and COVID, it's targetted to at home training, or training with minimial equipment and limited gym access.",
  buy_button_text: "GET THE BETA PROGRAM",
  terms:
    "Prices are in USD. The 5.13 Beta Program is a 3 month subscription or one-time fee. The Training Toolbox is an on-going subscription, which can be cancelled at any time. Please make sure your email is correct. Terms and Conditions Apply.",
  document_title: "5.13 Beta Program",
  monthly_key: "513beta-monthly",
  annual_key: "513beta-onetime",
  annual_multiplier: 1,
  paybutton_color: "#ff0000",
  purchase_success_url: "/purchase-success-513"
};

const ascensionToolboxMonthly = {
  ...ascensionToolboxBase,
  select_monthly: true
};
const ascensionToolboxYearly = {
  ...ascensionToolboxBase,
  select_monthly: false
};

const ascensionFriendsAndFamilySpecial = {
  ...ascensionToolboxBase,
  monthly_key: "ascension_toolbox_monthly_01_web_cad",
  annual_key: "ascension_toolbox_annual_01_web_cad",
  terms:
    "Prices are in CAD. The Training Toolbox is an on-going subscription, which can be cancelled at any time. Please make sure your email is correct. Terms and Conditions Apply."
};

const iansProgrammingSpecial = {
  ...ascensionToolboxBase,
  monthly_key: "ascension_toolbox_monthly_02_web_cad",
  annual_key: "ascension_toolbox_annual_01_web_cad",
  terms:
    "Prices are in CAD. The Training Toolbox is an on-going subscription, which can be cancelled at any time. Please make sure your email is correct. Terms and Conditions Apply.",
  show_save_badge: false,
  show_yearly_cost: true
};

export const get_checkout_config = checkout_key => {
  // console.log("key", checkout_key);
  switch (checkout_key) {
    case "513-beta-program-addon-toolbox":
      // console.log("beta");
      return base513;
      break;
    case "ascension-toolbox-monthly":
      return ascensionToolboxMonthly;
    case "ascension-toolbox-yearly":
      return ascensionToolboxYearly;
    case "toolbox-friends-and-family-special":
      return ascensionFriendsAndFamilySpecial;
    case "ascension-special":
      return ascensionFriendsAndFamilySpecial;
    case "ians-programming-special":
      return iansProgrammingSpecial;
    default:
      return ascensionToolboxBase;
  }
};

export const account_service_name = existing_account => {
  switch (existing_account.login_type) {
    case "email-password":
      return "email & password";
      break;
    case "facebook":
      return "Facebook";
      break;
    case "apple":
      return "Apple";
      break;
  }
};
