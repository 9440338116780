import React from "react";
import axios from "axios";
import { api_url, stripe_public_key } from "../../environment";

import styled from "styled-components";
import "./style.css";
import Header from "../../components/header";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Spinner from "../../components/spinner";
import SpinnerBlack from "../../components/spinner/black";

import {
  Logo,
  TrialTitle,
  ProductSpan1,
  ProductSpan2,
  ProductSpan3,
  RadioContainer,
  RadioBlock,
  RadioButton,
  RadioButtonWrapper,
  Seperator,
  UpsellWrapper
} from "../styledcomponents";
import Upsell from "./components/upsell_description";
import { account_service_name, get_checkout_config } from "./util";
import { check_is_valid, emailvalid } from "../common/util";

const ProductTitle = styled.div`
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 40px;
  word-break: break-word;
  text-align: left;
`;

const Description = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  color: white;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 16px;
`;

const PaymentDetailsText = styled.div`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: rgb(26, 46, 59);
  font-weight: 700;
  font-size: 18px;
  text-rendering: optimizelegibility;
`;

const Legal1 = styled.div`
  color: rgb(26, 46, 59);
  text-align: left;
  font-size: 14px;
  margin: 1.25rem 0px;
`;

const Legal2 = styled.div`
  color: rgb(26, 46, 59);
  text-align: left;
  font-size: 10px;
  margin: 1.25rem 0px;
  text-align: left;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
  border: 1px solid rgb(179, 191, 200);
  border-radius: 3px;

  box-sizing: border-box;
  outline-color: black; // #4D90FE
  outline-offset: 0px;
  outline-style: none;
  box-shadow: ${props => (props.card_focused ? "0 0 0 2px #000" : "0")};
`;

const Input = styled.input`
  width: 100%;
  height: 3.125rem;
  border: 1px solid rgb(179, 191, 200);
  color: rgb(26, 46, 59);
  padding: 0rem 0.9375rem 0px;
  border-radius: 3px;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  outline-style: none;
  &:focus {
    box-shadow: 0 0 0 2px #000;
  }
  &::placeholder {
    color: rgb(179, 191, 200);
  }
  &:disabled {
    background: #f6f6f6;
  }
`;

const PaymentDetailsWrapper = styled.div`
  padding: 1rem 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const TotalText = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
const Total = styled.div`
  font-size: 24px;
  font-weight: 400;
`;
const PayButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: white;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: 700;
  background-color: ${props => props.color};
  border-radius: 3px;
  border-color: transparent;
  margin: auto;
  margin-top: 10px;
  min-height: 40px;
`;

const SpinnerWrap = styled.div`
  margin-left: 5px;
`;
const SpinnerOverlay = styled.div`
  margin: 10px;
  position: absolute;
`;
const ErrorMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: red;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
const AccountMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
const SaveTag = styled.div`
  background-color: #33cc99;
  color: white;
  padding: 2px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  margin-left: 10px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const stripePromise = loadStripe(stripe_public_key);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_focused: false,
      select_monthly: true,
      select_addon: false,
      products: null,
      email: "",
      name: "",
      first_name: "",
      last_name: "",
      charge_error: false,
      existing_account: null,
      spinning: false,
      account_spinning: false
    };
  }

  componentDidMount = () => {
    const { checkoutkey } = this.props.match.params;
    const checkout_config = get_checkout_config(checkoutkey);
    document.title = checkout_config.document_title;
    axios.get(`${api_url}/api/v1/checkout_data`).then(res => {
      // console.log(res.data);
      this.setState({
        products: res.data,
        checkout_config,
        select_monthly: checkout_config.select_monthly
      });
    });
  };

  select_monthly = select_monthly => () => this.setState({ select_monthly });
  select_addon = () =>
    this.setState({ select_addon: !this.state.select_addon });

  get_cost_product = () => {
    let { select_monthly, select_addon, products } = this.state;
    let total, product;
    if (!products) return null;

    if (select_monthly && select_addon) {
      total = 2500 + 1200;
      product = products.find(p => p.key === "513beta-monthly-withapp");
    } else if (!select_monthly && select_addon) {
      total = 6000 + 1200;
      product = products.find(p => p.key === "513beta-onetime-withapp");
    } else if (select_monthly) {
      product = products.find(
        p => p.key === this.state.checkout_config.monthly_key
      );
      total = product.price;
    } else if (!select_monthly) {
      product = products.find(
        p => p.key === this.state.checkout_config.annual_key
      );
      total = product.price * this.state.checkout_config.annual_multiplier;
    }
    return { total, product };
  };

  payment_log = (status, message = "") => {
    let { name, email } = this.state;
    axios.post(`${api_url}/api/v1/payment_log`, {
      name,
      email,
      product_id: this.get_cost_product().product.id,
      status,
      message
    });
  };

  render() {
    let { no_header } = this.props;

    let {
      charge_error,
      name,
      email,
      first_name,
      last_name,
      password,
      existing_account,
      card_focused,
      select_monthly,
      products,
      select_addon,
      spinning,
      account_spinning,
      checkout_config,
      error_message,
      cardentry
    } = this.state;
    let totalobj = this.get_cost_product();
    if (!products) return null;
    const monthly_product = products.find(
      p => p.key === this.state.checkout_config.monthly_key
    );
    let monthly_price = monthly_product.price / 100;
    const yearly_product = products.find(
      p => p.key === this.state.checkout_config.annual_key
    );
    let yearly_price = this.state.checkout_config.show_yearly_cost
      ? (yearly_product.price / 100) * 12
      : yearly_product.price / 100;

    return (
      <div className="main-wrapper">
        <Header no_header />
        <div className="main-container">
          <div className="left-container">
            <ProductTitle>
              <div>
                <Logo
                  src={checkout_config.logo}
                  height={checkout_config.logo_height}
                />
              </div>
              {checkout_config.product_name}
            </ProductTitle>

            <Description>
              <strong>14 days free.</strong> It's a free trial, so you can try
              it without committing, just cancel before the trial ends.
            </Description>

            <RadioContainer onClick={this.select_monthly(true)}>
              <RadioBlock column>
                <ProductSpan1>{`Monthly`}</ProductSpan1>
                <ProductSpan2>
                  {" "}
                  {` $${monthly_price} ${monthly_product.currency} / month`}
                </ProductSpan2>
              </RadioBlock>
              <RadioButtonWrapper>
                <RadioButton selected={select_monthly} />
              </RadioButtonWrapper>
            </RadioContainer>

            <RadioContainer onClick={this.select_monthly(false)}>
              <RadioBlock column>
                <FlexRow>
                  <ProductSpan1>Yearly</ProductSpan1>
                  {checkout_config.show_save_badge ? (
                    <SaveTag>save 42%</SaveTag>
                  ) : null}
                </FlexRow>

                <ProductSpan2>{`$${yearly_price} ${yearly_product.currency} / ${
                  checkout_config.show_yearly_cost ? "year" : "month"
                }`}</ProductSpan2>
              </RadioBlock>
              <RadioButtonWrapper>
                <RadioButton selected={!select_monthly} />
              </RadioButtonWrapper>
            </RadioContainer>

            <Description>{checkout_config.description1}</Description>
          </div>
          <div className="right-container">
            {checkout_config.show_upsell ? (
              <Upsell
                select_addon={this.select_addon}
                is_select_addon={select_addon}
              />
            ) : null}

            <PaymentDetailsText>Account</PaymentDetailsText>

            <PaymentDetailsWrapper>
              <Input
                placeholder={"Email address"}
                onChange={e =>
                  this.setState({
                    email: e.target.value,
                    error_message: "",
                    existing_account: null
                  })
                }
                autocapitalize="none"
                disabled={spinning}
                type="email"
                onBlur={() => {
                  if (emailvalid(email))
                    this.setState({ account_spinning: true }, () => {
                      axios
                        .post(`${api_url}/api/v1/check_existing_user`, {
                          email
                        })
                        .then(res => {
                          if (res && res.data && res.data.status)
                            this.setState(
                              {
                                existing_account: res.data,
                                account_spinning: false
                              },
                              () => this._name_on_card.focus()
                            );
                          else {
                            this.setState({ account_spinning: false }, () =>
                              this._password.focus()
                            );
                          }
                        });
                    });
                }}
              />
              {existing_account ? (
                <AccountMessage>
                  There is an existing account for this email address using{" "}
                  {account_service_name(existing_account)} to login. We will
                  link your purchase to this account (recommended).
                </AccountMessage>
              ) : (
                <div>
                  {account_spinning ? (
                    <div>
                      <SpinnerOverlay>
                        <SpinnerBlack />
                      </SpinnerOverlay>
                    </div>
                  ) : null}
                  <Input
                    ref={ref => (this._password = ref)}
                    placeholder={"New Password"}
                    type="password"
                    onChange={e =>
                      this.setState({
                        password: e.target.value,
                        error_message: ""
                      })
                    }
                    disabled={
                      spinning || !emailvalid(email) || account_spinning
                    }
                  />
                  <Input
                    placeholder={"First name"}
                    onChange={e =>
                      this.setState({
                        first_name: e.target.value,
                        error_message: ""
                      })
                    }
                    disabled={
                      spinning || !emailvalid(email) || account_spinning
                    }
                  />
                  <Input
                    placeholder={"Last name"}
                    onChange={e =>
                      this.setState({
                        last_name: e.target.value,
                        error_message: ""
                      })
                    }
                    disabled={
                      spinning || !emailvalid(email) || account_spinning
                    }
                  />
                </div>
              )}
            </PaymentDetailsWrapper>
            <Seperator />
            <PaymentDetailsText>Payment Details</PaymentDetailsText>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <div>
                    <PaymentDetailsWrapper>
                      <Input
                        ref={ref => (this._name_on_card = ref)}
                        placeholder={"Name on card"}
                        onChange={e =>
                          this.setState({
                            name: e.target.value,
                            charge_error: false
                          })
                        }
                        disabled={spinning}
                      />
                      <InputWrapper card_focused={card_focused}>
                        <CardElement
                          onFocus={() => this.setState({ card_focused: true })}
                          onBlur={() => this.setState({ card_focused: false })}
                          onChange={val =>
                            this.setState({
                              cardentry: val,
                              charge_error: false
                            })
                          }
                          disabled={true}
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "rgb(26, 46, 59)",
                                "::placeholder": {
                                  color: "rgb(179, 191, 200)",
                                  fontWeight: "100"
                                }
                              },
                              invalid: {
                                color: "#9e2146"
                              }
                            }
                          }}
                        />
                      </InputWrapper>
                    </PaymentDetailsWrapper>
                    <Seperator />

                    <TotalWrapper>
                      <TotalText>Total:</TotalText>
                      <Total>
                        $ {(parseFloat(totalobj.total) / 100).toFixed(2)}
                      </Total>
                    </TotalWrapper>
                    {error_message ? (
                      <ErrorMessage>{error_message}</ErrorMessage>
                    ) : null}
                    <PayButton
                      color={checkout_config.paybutton_color}
                      onClick={() => {
                        if (spinning) return;
                        let error_message = check_is_valid(
                          email,
                          name,
                          cardentry,
                          existing_account,
                          first_name,
                          last_name,
                          password
                        );
                        if (error_message) {
                          this.setState({ error_message });
                          return;
                        }

                        const cardElement = elements.getElement(CardElement);
                        let product = totalobj.product;
                        this.setState(
                          { spinning: true, error_message: "" },
                          () => {
                            stripe
                              .createToken(cardElement)
                              .then(ret => {
                                let card_token = ret.token.id;
                                axios
                                  .post(`${api_url}/api/v1/process_payment`, {
                                    email,
                                    name,
                                    product_id: product.id,
                                    card_token,
                                    password,
                                    first_name,
                                    last_name
                                  })
                                  .then(response => {
                                    if (response.data.status === "fail") {
                                      this.payment_log(
                                        "fail",
                                        "{api_url}/api/v1/process_payment returned 200 but fail"
                                      );
                                      this.setState({
                                        error_message:
                                          "There was an error charging your card. Please check your card number & details and try again.",
                                        spinning: false
                                      });
                                      return;
                                    }

                                    this.setState({ spinning: false }, () => {
                                      this.payment_log("success");
                                      this.props.history.push({
                                        pathname:
                                          checkout_config.purchase_success_url,
                                        state: {
                                          email,
                                          existing_account
                                        }
                                      });
                                    });
                                  })
                                  .catch(err => {
                                    this.payment_log(
                                      "fail",
                                      "post to {api_url}/api/v1/process_payment was caught in failed"
                                    );
                                    this.setState({
                                      error_message:
                                        "There was an error charging your card. Please check your card number & details and try again.",

                                      spinning: false
                                    });
                                  });
                              })
                              .catch(err => {
                                this.payment_log(
                                  "fail",
                                  "stripe.createToken failed"
                                );
                                this.setState({
                                  error_message:
                                    "There was an error charging your card. Please check your card number & details and try again.",
                                  spinning: false
                                });
                              });
                          }
                        );
                      }}
                    >
                      {spinning
                        ? "COMPLETING"
                        : checkout_config.buy_button_text}
                      {spinning ? (
                        <SpinnerWrap>
                          <Spinner />
                        </SpinnerWrap>
                      ) : null}
                    </PayButton>
                  </div>
                )}
              </ElementsConsumer>
            </Elements>
            <Legal2>{checkout_config.terms}</Legal2>
          </div>
        </div>
      </div>
    );
  }
}
export default Checkout;
