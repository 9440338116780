import React from "react";

import styled from "styled-components";
import {
  AppImg,
  AppImages,
  Logo,
  ProductSpan1,
  ProductSpan3,
  RadioContainer,
  RadioBlock,
  RadioButtonWrapper,
  RadioButton,
  Seperator,
  TrialTitle,
  UpsellDescription,
  UpsellWrapper
} from "../../styledcomponents";

export default function upsell_description(props) {
  return (
    <React.Fragment>
      <TrialTitle>Training Toolbox</TrialTitle>
      <UpsellWrapper>
        <AppImages>
          <AppImg src="https://www.ascensionapp.com/wp-content/uploads/2020/11/AdobeStock_265130648-Converted2-01.png" />
          <AppImg src="https://www.ascensionapp.com/wp-content/uploads/2020/11/AdobeStock_265130648-Converted2-01.png" />
          <AppImg src="https://www.ascensionapp.com/wp-content/uploads/2020/11/AdobeStock_265130648-Converted2-01.png" />
          <AppImg src="https://www.ascensionapp.com/wp-content/uploads/2020/11/AdobeStock_265130648-Converted2-01.png" />
          <AppImg src="https://www.ascensionapp.com/wp-content/uploads/2020/11/AdobeStock_265130648-Converted2-01.png" />
        </AppImages>
        <UpsellDescription>
          Add on the Training Toolbox, you'll get:
          <ul>
            <li>
              Rehab & Physio exercises for finger injuries, elbow tendonitis,
              and shoulder pain and stability.
            </li>
            <li>
              Progression Programs for over 30 calithenic skills, including the
              one arm pull-up, front lever, muscle up, plance, and back lever.
            </li>
            <li>
              Numerous workouts for training power endurance, power endurance,
              and campus board.
            </li>
            <li>
              Interval timer for training finger repeaters or HIIT-style
              exercises.
            </li>
            <li>Fully featured logbook for tracking your progress.</li>
          </ul>
        </UpsellDescription>
      </UpsellWrapper>
      <RadioContainer onClick={props.select_addon}>
        <RadioBlock column>
          <ProductSpan3>Add on Training Toolbox</ProductSpan3>
          <ProductSpan1>
            <span style={{ textDecoration: "line-through", color: "red" }}>
              $25
            </span>{" "}
            $12 / month
          </ProductSpan1>
          <ProductSpan3>Save 40% when bundled</ProductSpan3>
        </RadioBlock>
        <RadioButtonWrapper>
          <RadioButton selected={props.is_select_addon} />
        </RadioButtonWrapper>
      </RadioContainer>
      <Seperator />
    </React.Fragment>
  );
}
