export const emailvalid = value =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
    ? true
    : false;

// const namevalid = value =>
//   value && /^[a-zA-Z]+ [a-zA-Z]+$/.test(value.trim()) ? true : false;

const namevalid = value =>
  value && /^[a-zA-Z\s.]*$/.test(value.trim()) ? true : false;
const singlenamevalid = value =>
  value && /^[a-zA-Z]+$/.test(value.trim()) ? true : false;

export const check_is_valid = (
  email,
  name,
  cardentry,
  existing_account,
  first_name,
  last_name,
  password
) => {
  // return "error message";
  const NAME_ERROR = "Please enter the name as shown on the credit card.";
  const CARD_ERROR = "Please check your card number & details and try again.";

  if (existing_account && existing_account.status) {
    if (!emailvalid(email)) return "email error";
    else if (!namevalid(name)) return NAME_ERROR;
    else if (!cardentry || !cardentry.complete) return CARD_ERROR;
  }
  // &&  &&
  else {
    if (!emailvalid(email))
      return "Please enter your email or check that you've entered it correctly.";
    else if (!password)
      return "Please enter a password you would like to use for your new account.";
    else if (!singlenamevalid(first_name))
      return "Please enter your first name.";
    else if (!singlenamevalid(last_name)) return "Please enter your last name.";
    else if (!namevalid(name)) return NAME_ERROR;
    else if (!cardentry || !cardentry.complete) return CARD_ERROR;
  }
};

export const check_is_valid_card_update = (name, cardentry) => {
  // return "error message";
  const NAME_ERROR = "Please enter the name as shown on the credit card.";
  const CARD_ERROR = "Please check your card number & details and try again.";

  if (!namevalid(name)) return NAME_ERROR;
  else if (!cardentry || !cardentry.complete) return CARD_ERROR;
};
