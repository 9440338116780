import "./style-black.css";

//info for this is at https://loading.io/css/
export default function spinner() {
  return (
    <div class="lds-black">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
