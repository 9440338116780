import { BrowserRouter, Switch, Route } from "react-router-dom";
import Checkout from "./pages/checkout";
import Buy from "./pages/buy";
import Header from "./components/header";
import PurchaseSuccess from "./pages/purchase_success";
import UpdateCard from "./pages/update_card";
import "./App.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route
            path="/buy/:checkoutkey"
            render={(props) => <Buy {...props} header />}
          />
          <Route
            path="/checkout/:checkoutkey"
            render={(props) => <Checkout {...props} header />}
          />
          <Route
            path="/checkout/$"
            render={(props) => <Checkout {...props} />}
          />
          <Route
            path="/purchase-success"
            render={(props) => <PurchaseSuccess {...props} />}
          />
          <Route
            path="/update_card/:stripe_customer_id"
            render={(props) => <UpdateCard {...props} />}
          />
          <Route path="/" render={(props) => <Checkout {...props} header />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
