import styled from "styled-components";

export const TrialTitle = styled.div`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: rgb(26, 46, 59);
  font-weight: 700;
  font-size: 22px;
  text-rendering: optimizelegibility;
`;

export const Logo = styled.img`
  height: ${(props) => props.height || "40px"};
  display: inline-block;
  // width: 121.59px;
`;

export const UpsellWrapper = styled.div`
  display: column;
  flex-direction: row;
  padding: 10px 0px 20px 0px;
`;

export const AppImg = styled.img`
  width: 60px;
`;

export const AppImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UpsellDescription = styled.div`
  padding: 10px;
  font-size: 14px;
  text-align: left;
`;

export const RadioContainer = styled.div`
  position: relative;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  border-radius: 3px;
  margin-bottom: 8px;
  height: 4rem;
  padding: 5px 20px;
  cursor: pointer;
  width: 100%;
  outline: currentcolor none medium;
  border: 1px solid gray;
  background-color: ${(props) => (props.dark ? "gray" : "rgb(255, 255, 255)")};
  color: ${(props) => (props.dark ? "white" : "rgb(26, 46, 59)")};
  box-sizing: border-box;
`;

export const RadioBlock = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) =>
    !props.column ? "flex-start" : "space-around"};
  -moz-box-align: center;
  align-items: ${(props) => (props.column ? "flex-start" : "center")};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

export const ProductSpan1 = styled.span`
  font-weight: bold;
  color: inherit;
  font-size: 16px;
  line-height: 16px;
`;
export const ProductSpan2 = styled.span`
  font-weight: normal;
  color: inherit;
  font-size: 16px;
  line-height: 16px;
`;
export const ProductSpan3 = styled.span`
  font-weight: normal;
  color: inherit;
  font-size: 14px;
  line-height: 14px;
`;
export const RadioButtonWrapper = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  height: 100%;
`;
export const RadioButton = styled.div`
  border: 1px solid rgb(179, 160, 160);
  border-radius: 100%;
  position: relative;
  width: 20px;
  height: 20px;

  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.selected ? "rgb(179, 160, 160)" : "transparent"};
    border-radius: 100%;

    transition: width, height;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
`;
export const Seperator = styled.div`
  border-top: 1px solid rgb(227, 232, 233);
  height: 1px;
  margin-bottom: 1.5rem;
`;
