import React from "react";
import axios from "axios";
import { api_url, stripe_public_key } from "../../environment";

import styled from "styled-components";
import "./style.css";
import Header from "../../components/header";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Spinner from "../../components/spinner";
import SpinnerBlack from "../../components/spinner/black";

import logoAscension from "../../assets/ascension_training_platform_logo.svg";

import {
  Logo,
  TrialTitle,
  ProductSpan1,
  ProductSpan2,
  ProductSpan3,
  RadioContainer,
  RadioBlock,
  RadioButton,
  RadioButtonWrapper,
  Seperator,
  UpsellWrapper
} from "../styledcomponents";
import { check_is_valid_card_update, emailvalid } from "../common/util";

const ProductTitle = styled.div`
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 40px;
  word-break: break-word;
  text-align: left;
`;

const Description = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  color: white;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 16px;
`;

const PaymentDetailsText = styled.div`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  text-rendering: optimizelegibility;
`;

const Legal1 = styled.div`
  color: rgb(26, 46, 59);
  text-align: left;
  font-size: 14px;
  margin: 1.25rem 0px;
`;

const Legal2 = styled.div`
  color: rgb(26, 46, 59);
  text-align: left;
  font-size: 10px;
  margin: 1.25rem 0px;
  text-align: left;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
  border: 1px solid rgb(179, 191, 200);
  border-radius: 3px;
  box-sizing: border-box;
  outline-color: black; // #4D90FE
  outline-offset: 0px;
  outline-style: none;
  background-color: #fff;
  box-shadow: ${props => (props.card_focused ? "0 0 0 2px #000" : "0")};
`;

const Input = styled.input`
  width: 100%;
  height: 3.125rem;
  border: 1px solid rgb(179, 191, 200);
  color: rgb(26, 46, 59);
  padding: 0rem 0.9375rem 0px;
  border-radius: 3px;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  outline-style: none;
  &:focus {
    box-shadow: 0 0 0 2px #000;
  }
  &::placeholder {
    color: rgb(179, 191, 200);
  }
  &:disabled {
    background: #f6f6f6;
  }
`;

const PaymentDetailsWrapper = styled.div`
  padding: 1rem 0;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const TotalText = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
const Total = styled.div`
  font-size: 24px;
  font-weight: 400;
`;
const PayButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: white;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: 700;
  background-color: ${props => props.color};
  border-radius: 3px;
  border-color: transparent;
  margin: auto;
  margin-top: 10px;
  min-height: 40px;
`;

const SpinnerWrap = styled.div`
  margin-left: 5px;
`;
const SpinnerOverlay = styled.div`
  margin: 10px;
  position: absolute;
`;
const ErrorMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: red;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
const SuccessMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: green;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
const AccountMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
const SaveTag = styled.div`
  background-color: #33cc99;
  color: white;
  padding: 2px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  margin-left: 10px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const stripePromise = loadStripe(stripe_public_key);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe_customer_id: null,
      card_focused: false,
      select_monthly: true,
      select_addon: false,
      products: null,
      email: "",
      name: "",
      first_name: "",
      last_name: "",
      charge_error: false,
      success_message: null,
      existing_account: null,
      spinning: false,
      account_spinning: false
    };
  }

  componentDidMount = () => {
    const { stripe_customer_id } = this.props.match.params;
    this.setState({ stripe_customer_id });
  };

  payment_log = (status, message = "") => {
    let { name, email } = this.state;
    axios.post(`${api_url}/api/v1/payment_log`, {
      name,
      status,
      message
    });
  };

  render() {
    let { no_header } = this.props;

    let {
      charge_error,
      name,
      email,
      first_name,
      last_name,
      password,
      existing_account,
      card_focused,
      select_monthly,
      products,
      select_addon,
      spinning,
      account_spinning,
      checkout_config,
      error_message,
      cardentry,
      stripe_customer_id,
      successful_update
    } = this.state;

    return (
      <div className="main-wrapper">
        <Header no_header />
        <div className="main-container">
          <div className="left-container">
            <ProductTitle>
              <div>
                <Logo src={logoAscension} height={"25px"} />
              </div>
              Update Card
            </ProductTitle>

            <Description>
              {!successful_update
                ? "There was a problem processing your card. Please update it below."
                : "Your card was updated successfully, thanks! You can close this window."}
            </Description>

            <Seperator />

            {!successful_update ? (
              <React.Fragment>
                <PaymentDetailsText>New Payment Details</PaymentDetailsText>
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ elements, stripe }) => (
                      <div>
                        <PaymentDetailsWrapper>
                          <Input
                            ref={ref => (this._name_on_card = ref)}
                            placeholder={"Name on card"}
                            onChange={e =>
                              this.setState({
                                name: e.target.value,
                                charge_error: false
                              })
                            }
                            disabled={spinning}
                          />
                          <InputWrapper card_focused={card_focused}>
                            <CardElement
                              onFocus={() =>
                                this.setState({ card_focused: true })
                              }
                              onBlur={() =>
                                this.setState({ card_focused: false })
                              }
                              onChange={val =>
                                this.setState({
                                  cardentry: val,
                                  charge_error: false
                                })
                              }
                              disabled={true}
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "rgb(26, 46, 59)",
                                    backgroundColor: "#fff",
                                    "::placeholder": {
                                      color: "rgb(179, 191, 200)",
                                      fontWeight: "100"
                                    }
                                  },
                                  invalid: {
                                    color: "#9e2146"
                                  }
                                }
                              }}
                            />
                          </InputWrapper>
                        </PaymentDetailsWrapper>
                        <Seperator />
                        {error_message ? (
                          <ErrorMessage>{error_message}</ErrorMessage>
                        ) : null}
                        <PayButton
                          color={"#33cc99"}
                          onClick={() => {
                            if (spinning) return;
                            let error_message = check_is_valid_card_update(
                              name,
                              cardentry
                            );
                            if (error_message) {
                              this.setState({ error_message });
                              return;
                            }

                            const cardElement = elements.getElement(
                              CardElement
                            );

                            this.setState(
                              { spinning: true, error_message: "" },
                              () => {
                                stripe
                                  .createToken(cardElement)
                                  .then(ret => {
                                    let card_token = ret.token.id;
                                    console.log(card_token);
                                    axios
                                      .post(`${api_url}/api/v1/update_card`, {
                                        name,
                                        stripe_customer_id,
                                        card_token
                                      })
                                      .then(response => {
                                        if (response.data.status === "fail") {
                                          this.payment_log(
                                            "fail",
                                            "{api_url}/api/v1/update_card returned 200 but fail"
                                          );
                                          this.setState({
                                            error_message:
                                              "There was an error updating your card. Please check your card number & details and try again.",
                                            spinning: false
                                          });
                                          return;
                                        }
                                        this.setState({
                                          spinning: false,
                                          successful_update: true
                                        });
                                      });
                                  })
                                  .catch(err => {
                                    this.payment_log(
                                      "fail",
                                      "stripe.createToken failed"
                                    );
                                    this.setState({
                                      error_message:
                                        "There was an error charging your card. Please check your card number & details and try again.",
                                      spinning: false
                                    });
                                  });
                              }
                            );
                          }}
                        >
                          {spinning ? "COMPLETING" : "UPDATE CARD"}
                          {spinning ? (
                            <SpinnerWrap>
                              <Spinner />
                            </SpinnerWrap>
                          ) : null}
                        </PayButton>
                      </div>
                    )}
                  </ElementsConsumer>
                </Elements>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default Checkout;
