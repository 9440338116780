import styled from "styled-components";

export const ProductTitle = styled.div`
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 38px;
  line-height: 50px;
  margin-bottom: 20px;
  word-break: break-word;
  text-align: left;
`;

export const Description = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  color: white;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 16px;
`;

export const PaymentDetailsText = styled.div`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: rgb(26, 46, 59);
  font-weight: 700;
  font-size: 18px;
  text-rendering: optimizelegibility;
`;

export const Legal1 = styled.div`
  color: rgb(26, 46, 59);
  text-align: left;
  font-size: 14px;
  margin: 1.25rem 0px;
`;

export const Legal2 = styled.div`
  color: rgb(26, 46, 59);
  text-align: left;
  font-size: 10px;
  margin: 1.25rem 0px;
  text-align: left;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
  border: 1px solid rgb(179, 191, 200);
  border-radius: 3px;

  box-sizing: border-box;
  outline-color: black; // #4D90FE
  outline-offset: 0px;
  outline-style: none;
  box-shadow: ${(props) => (props.card_focused ? "0 0 0 2px #000" : "0")};
`;

export const Input = styled.input`
  width: 100%;
  height: 3.125rem;
  border: 1px solid rgb(179, 191, 200);
  color: rgb(26, 46, 59);
  padding: 0rem 0.9375rem 0px;
  border-radius: 3px;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  outline-style: none;
  &:focus {
    box-shadow: 0 0 0 2px #000;
  }
  &::placeholder {
    color: rgb(179, 191, 200);
  }
  &:disabled {
    background: #f6f6f6;
  }
`;

export const PaymentDetailsWrapper = styled.div`
  padding: 1rem 0;
`;

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
export const TotalText = styled.div`
  font-size: 18px;
  font-weight: 700;
`;
export const Total = styled.div`
  font-size: 24px;
  font-weight: 400;
`;
export const PayButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: white;
  letter-spacing: 2px;
  font-size: 11px;
  font-weight: 700;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  border-color: transparent;
  margin: auto;
  margin-top: 10px;
  min-height: 40px;
`;

export const SpinnerWrap = styled.div`
  margin-left: 5px;
`;
export const SpinnerOverlay = styled.div`
  margin: 10px;
  position: absolute;
`;
export const ErrorMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: center;
  color: red;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
export const AccountMessage = styled.p`
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  cursor: auto;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
`;
export const SaveTag = styled.div`
  background-color: #33cc99;
  color: white;
  padding: 2px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  margin-left: 10px;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Price = styled.div`
  color: #57f563;
  text-align: left;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  height: ${(props) => props.height || "40px"};
  display: inline-block;
  border-radius: 10px;
  // width: 121.59px;
`;
export const AppImg = styled.img`
  width: 80%;
`;
