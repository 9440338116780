import React from "react";
import axios from "axios";
import { api_url, stripe_public_key } from "../../environment";

import styled from "styled-components";
import "./style.css";
import Header from "../../components/header";

import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Spinner from "../../components/spinner";
import SpinnerBlack from "../../components/spinner/black";

import {
  TrialTitle,
  ProductSpan1,
  ProductSpan2,
  ProductSpan3,
  RadioContainer,
  RadioBlock,
  RadioButton,
  RadioButtonWrapper,
  Seperator,
  UpsellWrapper,
} from "../styledcomponents";
import Upsell from "./components/upsell_description";
import { account_service_name, get_checkout_config } from "./util";
import { check_is_valid, emailvalid } from "../common/util";
import {
  ProductTitle,
  Description,
  PaymentDetailsText,
  Legal1,
  Legal2,
  InputWrapper,
  Input,
  PaymentDetailsWrapper,
  TotalWrapper,
  TotalText,
  Total,
  PayButton,
  SpinnerWrap,
  SpinnerOverlay,
  ErrorMessage,
  AccountMessage,
  SaveTag,
  FlexRow,
  Price,
  Logo,
} from "./styles";

const stripePromise = loadStripe(stripe_public_key);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_focused: false,
      select_monthly: true,
      select_addon: false,
      products: null,
      email: "",
      name: "",
      first_name: "",
      last_name: "",
      charge_error: false,
      existing_account: null,
      spinning: false,
      account_spinning: false,
      checkout_config: {},
    };
  }

  componentDidMount = () => {
    const { checkoutkey } = this.props.match.params;
    let checkout_config;
    axios
      .get(`${api_url}/api/v1/checkout_data?checkoutkey=${checkoutkey}`)
      .then((ret) => {
        let checkout_config = ret.data;
        document.title =
          checkout_config.document_title || "Ascension Training | Store";

        console.log("checkout_data", checkout_config);
        this.setState({
          products: [],
          checkout_config,
        });
      });
    // const checkout_config = get_checkout_config(checkoutkey);
  };

  select_monthly = (select_monthly) => () => this.setState({ select_monthly });
  select_addon = () =>
    this.setState({ select_addon: !this.state.select_addon });

  payment_log = (status, message = "") => {
    let { name, email } = this.state;
    axios.post(`${api_url}/api/v1/payment_log`, {
      name,
      email,
      product_id: "product_id",
      status,
      message,
    });
  };

  render() {
    let { no_header } = this.props;

    let {
      charge_error,
      name,
      email,
      first_name,
      last_name,
      password,
      existing_account,
      card_focused,
      select_monthly,
      products,
      select_addon,
      spinning,
      account_spinning,
      checkout_config,
      error_message,
      cardentry,
    } = this.state;

    let total = checkout_config && checkout_config.price;
    let totalobj = {};

    return (
      <div className="main-wrapper">
        <Header no_header />
        <div className="main-container">
          <div className="left-container">
            <ProductTitle>
              <div>
                <Logo
                  src={checkout_config.data && checkout_config.data.image}
                  height={checkout_config.logo_height || "240px"}
                />
              </div>
              {checkout_config.data && checkout_config.name}
            </ProductTitle>

            {/*<Description>
              <strong>14 days free.</strong> It's a free trial, so you can try
              it without committing, just cancel before the trial ends.
            </Description>*/}

            <Description>
              {checkout_config.data && checkout_config.data.description1}
            </Description>

            <Price>
              ${checkout_config.data && checkout_config.price / 100} CAD
            </Price>

            {/*<RadioContainer onClick={this.select_monthly(true)}>
              <RadioBlock column>
                <ProductSpan1>{`Monthly`}</ProductSpan1>
                <ProductSpan2>
                  {" "}
                  {` $${monthly_price} ${monthly_product.currency} / month`}
                </ProductSpan2>
              </RadioBlock>
              <RadioButtonWrapper>
                <RadioButton selected={select_monthly} />
              </RadioButtonWrapper>
            </RadioContainer>

            <RadioContainer onClick={this.select_monthly(false)}>
              <RadioBlock column>
                <FlexRow>
                  <ProductSpan1>Yearly</ProductSpan1>
                  {checkout_config.show_save_badge ? (
                    <SaveTag>save 42%</SaveTag>
                  ) : null}
                </FlexRow>

                <ProductSpan2>{`$${yearly_price} ${yearly_product.currency} / ${
                  checkout_config.show_yearly_cost ? "year" : "month"
                }`}</ProductSpan2>
              </RadioBlock>
              <RadioButtonWrapper>
                <RadioButton selected={!select_monthly} />
              </RadioButtonWrapper>
            </RadioContainer>*/}
          </div>
          <div className="right-container">
            {checkout_config.data && checkout_config.data.show_upsell ? (
              <Upsell
                select_addon={this.select_addon}
                is_select_addon={select_addon}
              />
            ) : null}

            <PaymentDetailsText>Setup Your Account</PaymentDetailsText>

            <PaymentDetailsWrapper>
              <Input
                placeholder={"Email address"}
                onChange={(e) =>
                  this.setState({
                    email: e.target.value,
                    error_message: "",
                    existing_account: null,
                  })
                }
                autocapitalize="none"
                disabled={spinning}
                type="email"
                onBlur={() => {
                  if (emailvalid(email))
                    this.setState({ account_spinning: true }, () => {
                      axios
                        .post(`${api_url}/api/v1/check_existing_user`, {
                          email,
                        })
                        .then((res) => {
                          if (res && res.data && res.data.status)
                            this.setState(
                              {
                                existing_account: res.data,
                                account_spinning: false,
                              },
                              () => this._name_on_card.focus(),
                            );
                          else {
                            this.setState({ account_spinning: false }, () =>
                              this._password.focus(),
                            );
                          }
                        });
                    });
                }}
              />
              {existing_account ? (
                <AccountMessage>
                  There is an existing account for this email address using{" "}
                  {account_service_name(existing_account)} to login. We will
                  link your purchase to this account (recommended).
                </AccountMessage>
              ) : (
                <div>
                  {account_spinning ? (
                    <div>
                      <SpinnerOverlay>
                        <SpinnerBlack />
                      </SpinnerOverlay>
                    </div>
                  ) : null}
                  <Input
                    ref={(ref) => (this._password = ref)}
                    placeholder={"New Password"}
                    type="password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        error_message: "",
                      })
                    }
                    disabled={
                      spinning || !emailvalid(email) || account_spinning
                    }
                  />
                  <Input
                    placeholder={"First name"}
                    onChange={(e) =>
                      this.setState({
                        first_name: e.target.value,
                        error_message: "",
                      })
                    }
                    disabled={
                      spinning || !emailvalid(email) || account_spinning
                    }
                  />
                  <Input
                    placeholder={"Last name"}
                    onChange={(e) =>
                      this.setState({
                        last_name: e.target.value,
                        error_message: "",
                      })
                    }
                    disabled={
                      spinning || !emailvalid(email) || account_spinning
                    }
                  />
                </div>
              )}
            </PaymentDetailsWrapper>
            <Seperator />
            <PaymentDetailsText>Payment Details</PaymentDetailsText>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <div>
                    <PaymentDetailsWrapper>
                      <Input
                        ref={(ref) => (this._name_on_card = ref)}
                        placeholder={"Name on card"}
                        onChange={(e) =>
                          this.setState({
                            name: e.target.value,
                            charge_error: false,
                          })
                        }
                        disabled={spinning}
                      />
                      <InputWrapper card_focused={card_focused}>
                        <CardElement
                          onFocus={() => this.setState({ card_focused: true })}
                          onBlur={() => this.setState({ card_focused: false })}
                          onChange={(val) =>
                            this.setState({
                              cardentry: val,
                              charge_error: false,
                            })
                          }
                          disabled={true}
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "rgb(26, 46, 59)",
                                "::placeholder": {
                                  color: "rgb(179, 191, 200)",
                                  fontWeight: "100",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </InputWrapper>
                    </PaymentDetailsWrapper>
                    <Seperator />

                    <TotalWrapper>
                      <TotalText>Total:</TotalText>
                      <Total>$ {(parseFloat(total) / 100).toFixed(2)}</Total>
                    </TotalWrapper>
                    {error_message ? (
                      <ErrorMessage>{error_message}</ErrorMessage>
                    ) : null}
                    <PayButton
                      color={checkout_config.paybutton_color || "#144bb8"}
                      onClick={() => {
                        if (spinning) return;
                        let error_message = check_is_valid(
                          email,
                          name,
                          cardentry,
                          existing_account,
                          first_name,
                          last_name,
                          password,
                        );
                        if (error_message) {
                          this.setState({ error_message });
                          return;
                        }

                        const cardElement = elements.getElement(CardElement);
                        let product = totalobj.product;
                        this.setState(
                          { spinning: true, error_message: "" },
                          () => {
                            stripe
                              .createToken(cardElement)
                              .then((ret) => {
                                let card_token = ret.token.id;
                                axios
                                  .post(`${api_url}/api/v2/process_payment`, {
                                    email,
                                    name,
                                    product_id: checkout_config.id,
                                    card_token,
                                    password,
                                    first_name,
                                    last_name,
                                    include_app_upsell: select_addon,
                                  })
                                  .then((response) => {
                                    if (response.data.status === "fail") {
                                      this.payment_log(
                                        "fail",
                                        "{api_url}/api/v2/process_payment returned 200 but fail",
                                      );
                                      this.setState({
                                        error_message:
                                          "There was an error charging your card. Please check your card number & details and try again.",
                                        spinning: false,
                                      });
                                      return;
                                    }

                                    this.setState({ spinning: false }, () => {
                                      this.payment_log("success");
                                      this.props.history.push({
                                        pathname:
                                          checkout_config.purchase_success_url ||
                                          "/purchase-success",
                                        state: {
                                          email,
                                          existing_account,
                                        },
                                      });
                                    });
                                  })
                                  .catch((err) => {
                                    this.payment_log(
                                      "fail",
                                      "post to {api_url}/api/v1/process_payment was caught in failed",
                                    );
                                    this.setState({
                                      error_message:
                                        "There was an error charging your card. Please check your card number & details and try again.",

                                      spinning: false,
                                    });
                                  });
                              })
                              .catch((err) => {
                                console.log(err);
                                this.payment_log(
                                  "fail",
                                  "stripe.createToken failed",
                                );
                                this.setState({
                                  error_message:
                                    "There was an error charging your card. Please check your card number & details and try again.",
                                  spinning: false,
                                });
                              });
                          },
                        );
                      }}
                    >
                      {spinning
                        ? "COMPLETING"
                        : checkout_config.buy_button_text || "BUY NOW"}
                      {spinning ? (
                        <SpinnerWrap>
                          <Spinner />
                        </SpinnerWrap>
                      ) : null}
                    </PayButton>
                  </div>
                )}
              </ElementsConsumer>
            </Elements>
            <Legal2>
              {checkout_config.terms || "Terms and Conditions Apply."}
            </Legal2>
          </div>
        </div>
      </div>
    );
  }
}
export default Checkout;
